import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/TypingIndicator.module.scss';

export const TypingIndicator: React.FC = () => {
  const { container, block, dot } = convertKeysToCamelCase(style);

  // * Three animated dots
  return (
    <div className={container}>
      <div className={block}>
        <div className={dot}></div>
        <div className={dot}></div>
        <div className={dot}></div>
      </div>
    </div>
  );
};

export default TypingIndicator;
