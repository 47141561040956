import React from 'react';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/SettingsCard.module.scss';
import { MaterialIcon } from '../../../common/MaterialIcon';

export type TSettingsActions = {
  actions?: {
    icon: string;
    action?: CallableFunction;
  }[];
};

export const SettingsCardActions: React.FC<TSettingsActions> = ({ actions }) => {
  const { iconWrapper } = convertKeysToCamelCase(style);

  return (
    <>
      {actions && (
        <div className={iconWrapper}>
          {actions.map(({ icon, action }, index) => {
            return <MaterialIcon key={index} icon={icon} onClick={action} />;
          })}
        </div>
      )}
    </>
  );
};
