import styles from '../../../assets/scss/components/chat/chat-content/PrivateNote.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { MaterialIcon } from '../../common/MaterialIcon';

interface IPrivateNoteProps {
  text: string;
}

export const PrivateNote: React.FC<IPrivateNoteProps> = ({ text }) => {
  const { privateNote } = convertKeysToCamelCase(styles);

  return (
    <div className={privateNote}>
      <MaterialIcon type={'outlined'} icon={'assignment'} />
      <p>{text}</p>
    </div>
  );
};

export default PrivateNote;
