import React from 'react';
import { ConnectionLineComponentProps } from 'react-flow-renderer';

export const ConnectionLine: React.FC<ConnectionLineComponentProps> = ({ sourceX, sourceY, targetX, targetY }) => {
  // TODO: Figure out how to have this connection line have the same form as loopback step edge
  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
      />
      <circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  );
};
