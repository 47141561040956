import { MiniMap, Controls, Background } from 'react-flow-renderer';
import { CustomControls } from './CustomControls';
import { BlocksSearch } from './BlocksSearch';

const styleMap: Record<string, string> = {
  input: '#0041d0',
  output: '#ff0072',
  default: '#1a192b',
};

export const DesignerFlowTools = () => {
  return (
    <>
      <CustomControls />
      <BlocksSearch />
      <MiniMap
        nodeStrokeColor={(n): string => {
          if (typeof n?.type !== 'string') {
            return n?.style?.background ? (n.style.background as string) : '#eee';
          }

          if (Boolean(styleMap[n.type])) {
            return styleMap[n?.type];
          }

          return '#eee';
        }}
        nodeColor={(n): string => {
          return n?.style?.background ? (n.style.background as string) : '#fff';
        }}
        nodeBorderRadius={2}
      />
      <Controls />
      <Background color="#aaa" gap={16} />
    </>
  );
};
