import React from 'react';
import MessageGroup from './MessageGroup';
import { useChatMessages } from '../../../hooks/chat/useChatMesages';

export const ChatMessageList: React.FC = () => {
  const { messageGroups } = useChatMessages();

  return (
    <>
      {messageGroups.map((messageGroup) => (
        <MessageGroup key={messageGroup.key} messages={messageGroup.messages} direction={messageGroup.direction} />
      ))}
    </>
  );
};

export default ChatMessageList;
