import { Actions } from '../../../constants/actionTypes';
import { TInbox } from '../../../interfaces/TChat';
import { useMainDispatch } from '../../useMainDispatch';
import { useMainSelector } from '../../useMainSelector';

export const useInboxes = () => {
  const {
    settings: { inboxes, selectedInbox },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const setSelectedInbox = (inbox: TInbox) => {
    dispatch({ type: Actions.SET_SETTINGS, payload: { selectedInbox: inbox } });
  };

  return { inboxes, selectedInbox, setSelectedInbox };
};
