import { useCallback } from 'react';
import { checkResetCodeApi } from '../../api/userApi';
import { Actions } from '../../constants/actionTypes';
import { useHistory } from 'react-router';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { useMainDispatch } from '../useMainDispatch';

interface IUseCheckResetCode {
  checkResetCode: (code: string) => Promise<void>;
}

export const useCheckResetCode = (): IUseCheckResetCode => {
  const dispatch = useMainDispatch();
  const history = useHistory();

  const checkResetCode = useCallback(
    async (code: string) => {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      try {
        await checkResetCodeApi({ code });
      } catch (error) {
        history.push(LOGIN_PAGE_ROUTE);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, history],
  );

  return { checkResetCode };
};
