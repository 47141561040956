import React from 'react';
import styles from '../../../assets/scss/components/chat/chat-content/ChatContent.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useTime } from '../../../hooks/useTime';

interface IChatContentProps {
  time: string;
}

export const ChatTimestamp: React.FC<IChatContentProps> = ({ time }) => {
  const { date } = convertKeysToCamelCase(styles);
  const { relativeDate } = useTime(time, 'MMMM D, HH:mm');
  return <p className={date}>{relativeDate}</p>;
};

export default ChatTimestamp;
