import { TMaterialIconType } from '../interfaces/IGeneral';

export const materialIconTypeMap: Record<TMaterialIconType, string> = {
  outlined: 'material-icons-outlined',
  'two-tone': 'material-icons-two-tone',
  filled: 'material-icons',
  rounded: 'material-icons-round',
  sharp: 'material-icons-sharp',
};

export const getMaterialIconType = (type?: TMaterialIconType) => {
  return materialIconTypeMap[type ?? 'filled'];
};
