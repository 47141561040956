/* eslint-disable @typescript-eslint/indent */
import { AxiosInstance } from 'axios';
import { TAxiosInterceptor } from '../../interfaces/IAxios';
import { Functionize } from '../../interfaces/ITypes';

export const interceptResponse =
  (instance: AxiosInstance) =>
  (interceptor: Functionize<TAxiosInterceptor, AxiosInstance>): AxiosInstance => {
    const { response: responseHandler, error: errorHandler } = interceptor(instance);
    instance.interceptors.response.use(responseHandler, errorHandler);

    return instance;
  };
