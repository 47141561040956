import React from 'react';

interface IHoverText extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text: string;
  onClick: () => void;
}

export const HoverText: React.FC<IHoverText> = ({ text, onClick, ...rest }) => {
  return (
    <div {...rest} onClick={onClick} role="button">
      {text}
    </div>
  );
};
