import React from 'react';
import { useLabelActions } from '../../../../hooks/settings/labels/useLabelActions';
import { useLabels } from '../../../../hooks/settings/labels/useLabels';
import { TLabelUpdateable } from '../../../../interfaces/TChat';
import { LabelDataModal } from './LabelDataModal';

export const EditLabelModal: React.FC = () => {
  const { updateLabel } = useLabelActions();
  const { selectedLabel } = useLabels();

  const onSubmit = async (labelData: TLabelUpdateable) => {
    if (!selectedLabel?.identifier) {
      return;
    }
    updateLabel(selectedLabel.identifier, labelData);
  };

  return (
    <LabelDataModal
      onSubmit={onSubmit}
      data={{
        labelColor: selectedLabel?.labelColor,
        labelName: selectedLabel?.labelName,
      }}
    />
  );
};

export default EditLabelModal;
