import { PASSWORD_RESET_ROUTE, LOGIN_PAGE_ROUTE, OTP_PAGE_ROUTE, FORGOT_PASSWORD_ROUTE } from '../../constants/routes';
import { AuthPage } from '../pages/AuthPage';
import { IRoute } from '../../interfaces/IRoutes';

export const UnauthenticatedAppRoutes: IRoute[] = [
  {
    path: LOGIN_PAGE_ROUTE,
    component: AuthPage,
    exact: true,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    component: AuthPage,
    exact: true,
  },
  {
    path: PASSWORD_RESET_ROUTE,
    component: AuthPage,
    exact: true,
  },
  {
    path: OTP_PAGE_ROUTE,
    component: AuthPage,
    exact: true,
  },
];
