import { useEffect } from 'react';
import { useCannedResponsesActions } from './useCannedResponsesActions';

export const useCannedResponsesInit = () => {
  const { getCannedResponses } = useCannedResponsesActions();

  useEffect(() => {
    getCannedResponses();
  }, [getCannedResponses]);
};
