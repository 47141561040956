import styles from '../../../../assets/scss/components/chat/chat-content/messages/TextWithButtonsMessage.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';

interface ITextWithButtonsMessageProps {
  messageLocation: string;
  text: string;
  buttons: string[];
}

export const TextWithButtonsMessage: React.FC<ITextWithButtonsMessageProps> = ({ messageLocation, text, buttons }) => {
  const { messageIn, messageInText, messageOut, messageOutText, buttonPillar, pillar } = convertKeysToCamelCase(styles);
  const messageClass = messageLocation === 'out' ? messageOut : messageIn;
  const messageText = messageLocation === 'out' ? messageOutText : messageInText;

  return (
    <>
      <div className={messageClass}>
        <div className={messageText}>
          <p>{text}</p>
        </div>
        <div className={buttonPillar}>
          {buttons.map((button, index) => {
            return (
              <div className={pillar} key={index}>
                <p>{button}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TextWithButtonsMessage;
