import { Actions } from '../../../constants/actionTypes';
import { TChatAgent } from '../../../interfaces/TChat';
import { useMainDispatch } from '../../useMainDispatch';
import { useMainSelector } from '../../useMainSelector';

export const useAgents = () => {
  const {
    settings: { agents, selectedAgent },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const setSelectedAgent = (agent: TChatAgent) => {
    dispatch({ type: Actions.SET_SETTINGS, payload: { selectedAgent: agent } });
  };

  return { agents, selectedAgent, setSelectedAgent };
};
