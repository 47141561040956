import { useCallback } from 'react';
import { BaseEmoji } from 'emoji-mart';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { Actions } from '../../constants/actionTypes';
import { useComponentVisible } from '../useComponentVisible';

export const useChatEmojiPicker = () => {
  const {
    ref: pickerContainerRef,
    isComponentVisible: showPicker,
    setIsComponentVisible: setShowPicker,
  } = useComponentVisible(false);

  const {
    chat: { chatInputMessage },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const hidePicker = useCallback(() => setShowPicker(false), [setShowPicker]);
  const togglePicker = useCallback(() => setShowPicker(!showPicker), [setShowPicker, showPicker]);

  const appendEmoji = useCallback(
    (emoji: BaseEmoji) => {
      const { native } = emoji;

      dispatch({ type: Actions.SET_CHAT, payload: { chatInputMessage: `${chatInputMessage}${native}` } });
      hidePicker();
    },
    [chatInputMessage, dispatch, hidePicker],
  );

  return {
    pickerContainerRef,
    showPicker,
    appendEmoji,
    togglePicker,
    hidePicker,
  };
};
