import React from 'react';
import { defaultWebclientConfig } from '../../../../helpers/webclientHelper';
import WebclientDataModal from './WebclientDataModal';
import { useWebclientActions } from '../../../../hooks/settings/webclients/useWebclientActions';
import { WebclientFormData } from '../../../../interfaces/IWebclient';

export const CreateWebclientConfigModal: React.FC = () => {
  const { createWebclientConfig } = useWebclientActions();

  const onSave = (data: WebclientFormData) => {
    return createWebclientConfig(data.name, data.config);
  };

  return <WebclientDataModal data={defaultWebclientConfig} name="New config" onSubmit={onSave} />;
};

export default CreateWebclientConfigModal;
