export enum ESocketEvents {
  // Receive events
  NEW_USER = 'newUser',
  NEW_CUSTOMER_MESSAGE = 'newUserMessage',
  REMOVE_USER_FROM_WAITING_LIST = 'passToHandover',
  CUSTOMER_CLOSED_CHAT = 'closeConversation',
  ASSIGN_USER_TO_QUEUE = 'assignedToQueue',

  // Send Events
  HANDOVER_TO_BOT = 'handoverToBotByAgent',
  NEW_AGENT_MESSAGE = 'newAgentMessage',
  NEW_AGENT_NOTE = 'newAgentNote',
  AGENT_TYPING = 'agentTyping',
}
