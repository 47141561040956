import React from 'react';
import { useEffect } from 'react';
import { useLogin } from '../../hooks/auth/useLogin';

export const LogoutPage: React.FC = () => {
  const { logout } = useLogin();
  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};
