import { useEffect } from 'react';
import { useInboxActions } from './useInboxActions';
import { useCompanySelector } from '../../useCompanySelector';

export const useInboxesInit = () => {
  const { fetchInboxes } = useInboxActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchInboxes();
  }, [fetchInboxes, selectedCompany]);
};
