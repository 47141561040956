import { FC } from 'react';
import { EdgeProps, getMarkerEnd } from 'react-flow-renderer';
import { useEdgePathHelper } from '../../../hooks/flow/useEdgePathHelper';

export const LoopbackStepEdge: FC<EdgeProps> = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  arrowHeadType,
  markerEndId,
  style = {},
}) => {
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const { d } = useEdgePathHelper({
    sourceX,
    sourceY,
    source,
    target,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <g className="react-flow__connection">
      <path id={id} className="react-flow__edge-path" d={d} markerEnd={markerEnd} style={style} />
    </g>
  );
};

export default LoopbackStepEdge;
