import { Redirect, Route, Switch } from 'react-router-dom';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { IRoute } from '../../interfaces/IRoutes';
import { UnauthenticatedAppRoutes } from '../routes/UnauthenticatedAppRoutes';

export const UnauthenticatedRouter: React.FC = () => {
  return (
    <Switch>
      {UnauthenticatedAppRoutes.map((route: IRoute) => {
        const { path, component, exact } = route;
        return <Route key={path} path={path} component={component} exact={exact} />;
      })}

      <Route path="*">
        <Redirect to={LOGIN_PAGE_ROUTE} />
      </Route>
    </Switch>
  );
};
