import { useMemo } from 'react';
import { getSmoothStepPath, Position, Node } from 'react-flow-renderer';
import { getLoopbackStepEdge } from '../../helpers/flowHelper';
import { EFlowDirection } from '../../interfaces/EFlowDirection';
import { useFlow } from './flow/useFlow';
import { useFlowNode } from './useFlowNode';

type TUseEdgeHelperParams = {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  source: string;
  target: string;
  sourcePosition: Position;
  targetPosition: Position;
};
type TUseEdgeHelper = (options: TUseEdgeHelperParams) => any;

export const useEdgePathHelper: TUseEdgeHelper = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  sourcePosition,
  targetPosition,
}) => {
  const { getNodes } = useFlowNode();
  const { flowDirection } = useFlow();

  const sourceNode = useMemo(() => getNodes().find((node: Node) => node.id === source), [source, getNodes]);
  const targetNode = useMemo(() => getNodes().find((node: Node) => node.id === target), [target, getNodes]);

  const d = useMemo(() => {
    // * When the target node is 'under' the source we render normally
    if (
      (flowDirection === EFlowDirection.Vertical && sourceY < targetY) ||
      (flowDirection === EFlowDirection.Horizontal && sourceX < targetX)
    ) {
      return getSmoothStepPath({
        sourceX: sourceX,
        sourceY: sourceY,
        sourcePosition: sourcePosition,
        targetPosition: targetPosition,
        targetX: targetX,
        targetY: targetY,
      });
    }

    //TODO: Resolve
    if (!sourceNode || !targetNode) {
      return '';
    }

    // * When the target node is on top of the source we render a loopback step
    return getLoopbackStepEdge({
      source: sourceNode,
      target: targetNode,
      sourceXYP: {
        x: sourceX,
        y: sourceY,
        position: sourcePosition,
      },
      targetXYP: {
        x: targetX,
        y: targetY,
        position: sourcePosition,
      },
    });
  }, [flowDirection, sourceY, targetY, sourceX, targetX, sourceNode, targetNode, sourcePosition, targetPosition]);

  return { d };
};
