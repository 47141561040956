import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProps {
  children: ReactElement; // Type children better since OverlayTrigger only accepts ReactElement but type of FC is ReactNode
  id: string;
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

export const GeneralTooltip: React.FC<IProps> = ({ children, text, placement, id }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id} placement={placement ?? 'bottom-end'}>
          <strong>{text}</strong>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};
