import React from 'react';
import { CannedResponseDataModal } from './CannedResponseDataModal';
import { useCannedResponses } from '../../../../hooks/chat/useCannedResponses';
import { useCannedResponsesActions } from '../../../../hooks/chat/useCannedResponsesActions';

export const EditCannedResponseModal: React.FC = () => {
  const { editCannedResponse } = useCannedResponsesActions();

  const { selectedCannedResponse } = useCannedResponses();

  const onSubmit = async (cannedResponseData: any) => {
    if (selectedCannedResponse?.identifier) {
      editCannedResponse(selectedCannedResponse.identifier, cannedResponseData);
    }
  };

  return (
    <CannedResponseDataModal
      onSubmit={onSubmit}
      cannedResponseData={{
        message: selectedCannedResponse?.message,
        shorthand: selectedCannedResponse?.shorthand,
      }}
    />
  );
};

export default EditCannedResponseModal;
