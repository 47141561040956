import React from 'react';
import { LabelDataModal } from './LabelDataModal';
import { useLabelActions } from '../../../../hooks/settings/labels/useLabelActions';

export const CreateLabelModal: React.FC = () => {
  const { createLabel } = useLabelActions();
  return <LabelDataModal onSubmit={createLabel} />;
};

export default CreateLabelModal;
