import { useEffect } from 'react';
import { useAgentActions } from './useAgentActions';
import { useCompanySelector } from '../../useCompanySelector';

export const useAgentsInit = () => {
  const { fetchAgents } = useAgentActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents, selectedCompany]);
};
