import { Actions } from '../../../constants/actionTypes';
import { useMainDispatch } from '../../useMainDispatch';
import { useMainSelector } from '../../useMainSelector';
import { useMemo, useCallback } from 'react';

export const useWebclient = () => {
  const {
    settings: { webclient },
  } = useMainSelector();

  const dispatch = useMainDispatch();

  const setSelectedWebclientConfig = useCallback(
    (configId: string) => {
      dispatch({ type: Actions.SET_SETTINGS, payload: { webclient: { ...webclient, selectedConfigId: configId } } });
    },
    [webclient, dispatch],
  );

  const selectedWebclientConfig = useMemo(
    () => webclient.configs.find(({ identifier }) => identifier === webclient.selectedConfigId),
    [webclient.configs, webclient.selectedConfigId],
  );

  const setSelectedWhitelistedDomain = useCallback(
    (selectedWhitelistedDomain: string) => {
      dispatch({ type: Actions.SET_SETTINGS, payload: { webclient: { ...webclient, selectedWhitelistedDomain } } });
    },
    [webclient, dispatch],
  );

  return {
    webclient,
    selectedWebclientConfig,
    setSelectedWebclientConfig,
    setSelectedWhitelistedDomain,
  };
};
