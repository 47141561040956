import React from 'react';
import style from '../../assets/scss/components/auth/CompanyCard.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';

interface Props {
  iconUrl: string;
  name: string;
  url: string;
  onPress: () => void;
}

export const CompanyCard: React.FC<Props> = ({ iconUrl, name, url, onPress }) => {
  const { wrapper, imageContainer, title } = convertKeysToCamelCase(style);

  return (
    <div
      role="button"
      className={`d-flex flex-row  align-items-center mx-3 my-2 bg-white ${wrapper}`}
      onClick={onPress}
    >
      <div className={imageContainer}>
        <img src={iconUrl} />
      </div>
      <div>
        <h4 className={title}>{name}</h4>
        <div>{url}</div>
      </div>
    </div>
  );
};

export default CompanyCard;
