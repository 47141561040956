type TDownloadFileParams = {
  data: BlobPart;
  fileName: string;
  fileType: string;
};

type TDownloadFileTypeParam = Omit<TDownloadFileParams, 'fileType'>;

export const useFileDownload = () => {
  const download = ({ data, fileName, fileType }: TDownloadFileParams) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return {
    downloadJSON: ({ data, fileName }: TDownloadFileTypeParam) => {
      download({ data, fileName, fileType: 'text/json' });
    },
    downloadCSV: ({ data, fileName }: TDownloadFileTypeParam) => {
      download({ data, fileName, fileType: 'text/csv' });
    },
  };
};
