import { useMainSelector } from '../../useMainSelector';

export const useFlow = () => {
  const {
    designer: { selectedBlock, flowDirection, blocksSearchString, blocksSearchMatchIndex },
  } = useMainSelector();

  return {
    selectedBlock,
    flowDirection,
    flowSearchQuery: blocksSearchString,
    flowMatchIndex: blocksSearchMatchIndex,
  };
};
