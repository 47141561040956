import { useEffect, useRef } from 'react';

export default function useUpdateEffect(callback: CallableFunction, dependencies: Array<any>) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}
