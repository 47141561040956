import { useEffect, useCallback } from 'react';
import { useModal } from './useModal';

export const useModalListeners = () => {
  const { hideModal } = useModal();

  const handleEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideModal();
      }
    },
    [hideModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscPressed, false);
    return () => {
      document.removeEventListener('keydown', handleEscPressed, false);
    };
  }, [handleEscPressed]);
};
