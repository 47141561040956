import { useCallback } from 'react';
import { useFlow } from './flow/useFlow';
import { useFlowActions } from './flow/useFlowActions';
import { useFlowNode } from './useFlowNode';

export const useFlowSearch = () => {
  const { flowMatchIndex, flowSearchQuery } = useFlow();
  const { filteredNodesCount } = useFlowNode();
  const { setMatchIndex, setSearchString } = useFlowActions();

  const getNextMatch = useCallback(() => {
    if (flowMatchIndex < filteredNodesCount - 1) {
      setMatchIndex(flowMatchIndex + 1);
    }
  }, [flowMatchIndex, filteredNodesCount, setMatchIndex]);

  const getPreviousMatch = useCallback(() => {
    if (flowMatchIndex > 0) {
      setMatchIndex(flowMatchIndex - 1);
    }
  }, [flowMatchIndex, setMatchIndex]);

  return {
    searchString: flowSearchQuery,
    setSearchString,
    getNextMatch,
    getPreviousMatch,
    blockSearchMatchStatus: filteredNodesCount ? `${flowMatchIndex + 1} of ${filteredNodesCount}` : '',
  };
};
