/* eslint-disable @typescript-eslint/indent */
import { TReduxAction, TReduxState, TReduxHandler } from '../../interfaces/TReduxState';

export const reducerFactory =
  (initialState: TReduxState, handlers: TReduxHandler) =>
  (state = initialState, action: TReduxAction): TReduxState => {
    const { payload, type } = action;
    const handler = handlers[type];

    if (handler) {
      return handler(state, payload);
    }

    return state;
  };
