import React from 'react';
import { CannedResponseDataModal } from './CannedResponseDataModal';
import { useCannedResponsesActions } from '../../../../hooks/chat/useCannedResponsesActions';

export const CreateCannedResponseModal: React.FC = () => {
  const { createCannedResponse } = useCannedResponsesActions();

  return <CannedResponseDataModal onSubmit={createCannedResponse} />;
};

export default CreateCannedResponseModal;
