import { useCallback, useEffect, useMemo } from 'react';

import { Elements, FlowElement, isNode, Node, Position } from 'react-flow-renderer';
import dagre, { Node as DagreNode } from 'dagre';
import { EFlowDirection } from '../../interfaces/EFlowDirection';
import { TBlockWithPosition } from '../../interfaces/TDesigner';
import { useFlow } from './flow/useFlow';

const defaultDagreNodeDimensions = {
  width: 230,
  height: 75,
};

export const useDagre = () => {
  const { flowDirection } = useFlow();
  const dagreGraph = useMemo(() => new dagre.graphlib.Graph(), []);

  useEffect(() => {
    dagreGraph.setDefaultEdgeLabel(() => ({}));
  }, [dagreGraph]);

  const getPositionsFromLayout = useCallback(
    (element: Node<TBlockWithPosition>, nodeWithPosition: DagreNode) => {
      const isVertical = flowDirection === EFlowDirection.Vertical;
      const position = isVertical ? element.data?.verticalPosition : element.data?.horizontalPosition;

      const defaultPositions = {
        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        x: nodeWithPosition.x - defaultDagreNodeDimensions.width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - defaultDagreNodeDimensions.height / 2,
      };

      if (!position) {
        return {
          x: defaultPositions.x,
          y: defaultPositions.y,
        };
      }

      return {
        x: position?.x > 0 ? position.x : defaultPositions.x,
        y: position.y > 0 ? position.y : defaultPositions.y,
      };
    },
    [flowDirection],
  );

  const computeDimensionsForNode = (node: Node<TBlockWithPosition>) => {
    if (node?.__rf?.width && node?.__rf?.height) {
      //* Use actual node position whenever possible
      return {
        width: node?.__rf?.width,
        height: node?.__rf?.height,
      };
    }

    if (!node?.data) {
      // * Use default if node is empty
      return { ...defaultDagreNodeDimensions };
    }

    switch (node.data.type) {
      // * Worst case, get a rough estimate based on node type
      case 'text':
      case 'textWithButtons':
        return {
          height: 300,
          width: 700,
        };
      case 'databaseUpdate':
      case 'databaseRead':
      case 'databaseDelete':
        return {
          height: 200,
          width: 250,
        };
      default:
        return { ...defaultDagreNodeDimensions };
    }
  };

  const addElementsToDagreAndPositionInLayout = useCallback(
    (elements: Elements<TBlockWithPosition>) => {
      const isHorizontal = flowDirection === EFlowDirection.Horizontal;
      const rankdir = isHorizontal ? 'LR' : 'TB';
      dagreGraph.setGraph({ rankdir, ranker: 'tight-tree' });

      elements.map((el: FlowElement<TBlockWithPosition>) =>
        isNode(el) ? dagreGraph.setNode(el.id, computeDimensionsForNode(el)) : dagreGraph.setEdge(el.source, el.target),
      );

      dagre.layout(dagreGraph);

      return elements.map((element: FlowElement<TBlockWithPosition>) => {
        if (!isNode(element)) {
          return element;
        }

        const nodeWithPosition = dagreGraph.node(element.id);

        const nodeProperties = {
          ...element,
          targetPosition: isHorizontal ? Position.Left : Position.Top,
          sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        };

        return {
          ...nodeProperties,
          position: getPositionsFromLayout(element, nodeWithPosition),
        };
      });
    },
    [dagreGraph, flowDirection, getPositionsFromLayout],
  );

  return {
    addElementsToDagreAndPositionInLayout,
    getDefaultDimensions: () => defaultDagreNodeDimensions,
    dagreGraph,
  };
};
