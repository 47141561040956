import { SIGN_IN_DEFAULT_VALUES } from '../../constants/login';
import { getSignInValidationSchema } from '../../helpers/validationSchemaHelpers';
import { useLogin } from './useLogin';
import { ILoginFormInput, IUserLoginPayload } from '../../interfaces/ILogin';
import { useValidatedForm } from '../useValidatedForm';
import { BaseSyntheticEvent } from 'react';
import { IUseForm } from '../../interfaces/IGeneral';

interface IUseSignInForm extends IUseForm<ILoginFormInput> {
  submitSignIn: (e?: BaseSyntheticEvent) => any;
}

export const useSignInForm = (): IUseSignInForm => {
  const { registerInput, handleSubmit, reset, validationErrors } = useValidatedForm<ILoginFormInput>({
    schema: getSignInValidationSchema(),
    defaultValues: SIGN_IN_DEFAULT_VALUES,
  });

  const { submitLogin } = useLogin();

  const signIn = (userData: IUserLoginPayload) => {
    reset();
    submitLogin(userData);
  };

  return { submitSignIn: handleSubmit(signIn), registerInput, validationErrors };
};
