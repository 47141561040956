import React from 'react';
import { useWebclient } from '../../../../hooks/settings/webclients/useWebclient';
import { useWebclientActions } from '../../../../hooks/settings/webclients/useWebclientActions';
import { WebclientFormData } from '../../../../interfaces/IWebclient';
import WebclientDataModal from './WebclientDataModal';

export const EditWebclientConfigModal: React.FC = () => {
  const { selectedWebclientConfig } = useWebclient();

  const { updateWebclientConfig } = useWebclientActions();

  if (!selectedWebclientConfig) {
    return null;
  }

  const onSave = (data: WebclientFormData) => {
    return updateWebclientConfig(selectedWebclientConfig.identifier, data.name, data.config);
  };

  return (
    <WebclientDataModal data={selectedWebclientConfig?.config} name={selectedWebclientConfig?.name} onSubmit={onSave} />
  );
};

export default EditWebclientConfigModal;
