import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import { reportWebVitals } from './reportWebVitals';
import './assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
