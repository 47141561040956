import React from 'react';
import { useAgentActions } from '../../../../hooks/settings/agents/useAgentActions';
import AgentDataModal from './AgentDataModal';

export const CreateAgentModal: React.FC = () => {
  const { createAgent } = useAgentActions();

  return <AgentDataModal onSubmit={createAgent} />;
};

export default CreateAgentModal;
