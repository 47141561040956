import { useEffect } from 'react';
import { useLabelActions } from './useLabelActions';

export const useLabelInit = () => {
  const { getLabels } = useLabelActions();

  useEffect(() => {
    getLabels();
  }, [getLabels]);
};
