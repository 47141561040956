import { AxiosInstance } from 'axios';
import { getTokenRefreshInterceptor } from '../../helpers/axiosInterceptorHelpers';
import { axiosDefault } from './axios';
import { interceptResponse } from './axiosInterceptors';

/**
 * Get an axios instance that has interceptors setup
 * @param params  Parameters to pass to Axios
 * @returns The axios instance
 */
export const axiosInterceptAccessTokenExpiry = (): AxiosInstance => {
  const interceptors = [getTokenRefreshInterceptor];

  const instance = axiosDefault();
  interceptors.forEach(interceptResponse(instance));

  return instance;
};
