import { useContext, useCallback } from 'react';
import { WebSocketContext } from '../components/layouts/AuthenticatedLayout';

export const useSocket = () => {
  const socketRef = useContext(WebSocketContext);

  const send = useCallback(
    (event: string, message: any) => {
      if (!socketRef?.current) {
        return false;
      }
      socketRef.current.emit(event, message);
    },
    [socketRef],
  );

  return {
    send,
  };
};

export default useSocket;
