import { useMemo, useState } from 'react';

export const useCollapsedList = <T>(list?: T[], numberOfElementsToShow = 3, showAllByDefault = false) => {
  const [showAll, setShowAll] = useState(showAllByDefault);

  const elementsToShow = useMemo(() => {
    if (!list || !list.length) {
      return [];
    }

    if (showAll || list.length <= numberOfElementsToShow) {
      return list;
    }

    return list.slice(0, 3);
  }, [list, showAll, numberOfElementsToShow]);

  return { showAll, setShowAll, elementsToShow, hasMoreElements: (list?.length || 0) > numberOfElementsToShow };
};
