import React from 'react';
import InboxDataModal from './InboxDataModal';
import { useInboxActions } from '../../../../hooks/settings/inboxes/useInboxActions';

export const CreateInboxModal: React.FC = () => {
  const { createInbox } = useInboxActions();

  return <InboxDataModal onSubmit={createInbox} />;
};

export default CreateInboxModal;
