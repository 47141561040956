import { MFA_DEFAULT_VALUE } from '../../constants/login';
import { get2FATokenValidationSchema } from '../../helpers/validationSchemaHelpers';
import { useLogin } from './useLogin';
import { ILogin2FaInput, IUserLoginPayload } from '../../interfaces/ILogin';
import { useValidatedForm } from '../useValidatedForm';
import { IUseForm } from '../../interfaces/IGeneral';

interface IUse2FAForm extends IUseForm<ILogin2FaInput> {
  submitSignInAfter2FA: (data: IUserLoginPayload) => any;
}

export const use2FAForm = (): IUse2FAForm => {
  const { registerInput, handleSubmit, reset, validationErrors } = useValidatedForm<ILogin2FaInput>({
    schema: get2FATokenValidationSchema(),
    defaultValues: MFA_DEFAULT_VALUE,
  });

  const { submitLogin } = useLogin();

  const signIn = (data: IUserLoginPayload) => (userData: ILogin2FaInput) => {
    reset();
    const loginData = { ...userData, ...data };
    submitLogin(loginData);
  };

  return {
    submitSignInAfter2FA: (data: IUserLoginPayload) => handleSubmit(signIn(data)),
    registerInput,
    validationErrors,
  };
};
