import React from 'react';
import { getCSS } from '../../helpers/baseHelpers';
import MaterialIcon from './MaterialIcon';

type TProps = {
  text?: string;
  onClickAdd?: CallableFunction;
};

export const AddButton: React.FC<TProps> = ({ text, onClickAdd }) => {
  return (
    <button className={`general-add-button ${getCSS(!!text, 'with-text')}`} onClick={() => onClickAdd?.()}>
      <div className="icon">
        <MaterialIcon icon={'add'} />
      </div>
      {text && <p className="label">{text}</p>}
    </button>
  );
};

export default AddButton;
