import styles from '../../../../assets/scss/components/chat/chat-content/messages/ImageMessage.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';

interface IImageMessageProps {
  messageLocation: string;
  image: string;
}

export const ImageMessage: React.FC<IImageMessageProps> = ({ messageLocation, image }) => {
  const { messageOut, messageIn } = convertKeysToCamelCase(styles);
  const messageClass = messageLocation === 'out' ? messageOut : messageIn;

  return (
    <div className={messageClass}>
      <img width={160} height={160} src={image}></img>
    </div>
  );
};

export default ImageMessage;
