import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { IS_DEVELOPMENT_ENVIRONMENT } from '../constants/env';
import { initI18N } from '../config/i18n';
import rootReducer from '../redux/reducers/index';
import { Main } from './Main';

// * If on development environment then call create store with redux devtools extension
const store = !IS_DEVELOPMENT_ENVIRONMENT
  ? createStore(rootReducer)
  : createStore(
      rootReducer,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    );

initI18N();

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <Main />
      </React.StrictMode>
    </Provider>
  );
};
