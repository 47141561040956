import { useCallback, useEffect, useState } from 'react';
import { useBlocksActions } from './useBlocksActions';

export const useBlocksInit = () => {
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');
  const { getBlocks } = useBlocksActions();

  const getBlocksStatus = useCallback(async () => {
    const status = await getBlocks();
    setStatus(status);
  }, [getBlocks]);

  useEffect(() => {
    getBlocksStatus();
  }, [getBlocksStatus]);

  return { loading: status === 'loading', error: status === 'error' };
};
